<template>
<div>
    <div>
        <template>
            <template v-if="!isMobile">
                <v-img 
                    src="https://dev.coneldoradovalesoro.com.mx/banner_us.png"
                    class="grey darken-4 "
                ></v-img>
            </template>
            <template v-else>
                <v-img 
                     src="https://dev.coneldoradovalesoro.com.mx/banner_mobil.png"
                    class="grey darken-4 "
                ></v-img>
            </template>
        </template>
            <br>
            <v-container class="mt-5">
                <div class="text-center mt-5" style="color:#000;">
                    <h1>Bienvenido</h1>
                    <h5 style="color:rgb(248 172 0);">CON EL DORADO VALES ORO</h5>
                </div>
                  <b-row class="justify-content-center mt-5">
                      <b-col cols="12" md="5" >
                  <br>
                    <b-input-group prepend="Teléfono" class="mt-3">
                      <b-form-input v-model="telefono"></b-form-input>
                      <b-input-group-append>
                        <b-button variant="outline-success" @click="consultaPhone()">Consultar</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  <br>
                    
                          <v-card elevation="4" shaped outlined>                       
                              <v-card-text class="mt-2">        
                                  <h4 class="text-center mt-0" style="color:#000;">Tu posición:</h4>                   
                                  <h1 v-if="buscar == 1" class="text-center" style="color:rgb(234 76 32); font-size:60px;">{{dorado.ranking}}</h1>
                                  <h1 v-else class="text-center" style="color:rgb(234 76 32); font-size:60px;">00</h1>
                                  <h4 class="text-center mt-0" style="color:#000;">en el ranking, con:</h4>
                                  <h1 v-if="buscar == 1" class="text-center" style="color:rgb(234 76 32); font-size:60px;">{{dorado.puntos}}</h1>
                                  <h1 v-else class="text-center" style="color:rgb(234 76 32); font-size:60px;">00</h1>
                                  <h4 class="text-center mt-0" style="color:#000;">puntos al momento.</h4>
                                  <p class="mt-5 text-center" style="color:#000; font-size:17px;" >Nombre: {{dorado.nombre}}<br>
                                                                                  Teléfono: {{dorado.telefono}}<br>
                                  </p>                 
                              </v-card-text>                          
                          </v-card>
                          <div class="text-center">
                              <b-row class="justify-content-center">
                                  <b-col cols="12" md="12">
                                    <p><b>Recuerda que entre más tickets registres más oportunidades tendrás de sumar puntos y ganar uno de los 15 premios.</b></p>
                                  </b-col>
                              </b-row>    
                          </div>
                          <br>
                          <hr>
                          <br>
                          <br>
                      </b-col>
                  </b-row>
        </v-container>        
    </div>
      <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
        style="background:#ea4c20;"
        width="500"
      >
        
        <v-card>
          <v-toolbar
            dark
            color="#ea4c20"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Con el Dorado Vales Oro</v-toolbar-title>
          </v-toolbar>
          <v-container class="mt-5 text-center">
              <center>
                <v-img  src="https://dev.coneldoradovalesoro.com.mx/reloj.png" width="160"></v-img>
              </center>
              <br><br>
              <h4 class="text-center mt-0" style="color:rgb(248 172 0);">
                Tus datos están en proceso de registro por favor regresa más tarde
              </h4>  
              <br><br>
              <hr style=" border-top: 3px solid #979797 !important;">
          
          </v-container>        
        </v-card>
      </v-dialog>
</div>
</template>
<script>
import Swal from 'sweetalert2'
  export default {
    data () {
      return {
        loader:false,
        isMobile: this.$mobile(),
        telefono:"",
        dorado:{},
        buscar:0,
        dialog:false
      }
    },
    methods:{
        consultaPhone(){
          if (!this.telefono) {
            Swal.fire({
              icon: 'error',
              title: 'Teléfono incorrecto',
              text: 'Verifica y vuelve   a intentar'
            })
          }else{
            var url= 'rankin/lugar?id='+ this.telefono;
            this.$api.get(url).then(
                  ({data}) => {
                    if (data.valida == 1)
                    {
                    this.dialog = true;
                    }else{
                      this.dorado = data.info; 
                      this.buscar = 1;
                    }                 
                  }
              );
          }
        }
    }
  }
</script>