<template>
  <v-footer
    dark
    padless
    
  >
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        style="background:#ea4c20;"
      >
        
        <v-card>
          <v-toolbar
            dark
            color="#ea4c20"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="footer == 1">Términos y Condiciones</v-toolbar-title>
            <v-toolbar-title v-else-if="footer == 2">Aviso de Privacidad</v-toolbar-title>
            <v-toolbar-title v-else-if="footer == 3">Contáctanos</v-toolbar-title>
          </v-toolbar>
          <v-container class="mt-5">
              <v-img src="https://dev.coneldoradovalesoro.com.mx/logo.png" max-width="150"></v-img>
              <br><br>
              <hr style=" border-top: 3px solid #979797 !important;">
            <template v-if="footer == 1">     
                
                <div class=WordSection1>

                <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>TÉRMINOS
                LEGALES DE LA PROMOCIÓN.</span></b></p>

                <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>1)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Al
                momento de la aceptación del Premio, el Participante se obliga a sacar en paz y
                a salvo al Proveedor y organizador, así como sus accionistas, directores,
                empleados, miembros del consejo de administración, dependientes, entre otros,
                de cualquier queja, reclamación, denuncia o cualquier otro equivalente,
                relacionada con cualquier daño, pérdida, gasto, erogación o contingencia,
                originada con motivo de la aceptación, utilización o aprovechamiento del Premio
                por el Participante. Los participantes aceptan la responsabilidad derivada del
                uso y aprovechamiento del Premio o de su participación dentro de la Promoción,
                por cualquier daño, pérdida o contingencia que pudiere ocasionarse o ser
                reclamada por cualquier tercero.</span></p>

                <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>2)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>La
                información de los Participantes, proporcionada con motivo del registro de su
                participación, será considerada como confidencial y únicamente será utilizada
                para las finalidades y conforme a los términos previstas en el respectivo Aviso
                de Privacidad, el cual forma parte integrante de las presentes Bases de la
                Promoción y cuya aceptación es requisito indispensable para ser Participante en
                la Promoción.</span></p>

                <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                <p class=MsoNormal style='margin-left:35.4pt;text-align:justify'><span
                lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Los
                Participantes se obligan a proporcionar toda la información y/o documentación
                requerida conforme a las Bases de forma veraz, en el momento y tiempo que el
                Proveedor se los solicite a efecto de verificar y corroborar los términos de su
                participación. </span></p>

                <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>3)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>El
                Proveedor no será responsable por la falsedad de los datos proporcionados, con
                motivo del registro del Participante en la Promoción; éste último será el único
                y exclusivo responsable de la información otorgada. </span></p>

                <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>4)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Para
                efectos de cualquier contingencia, queja, denuncia, reclamación o conflicto o
                interpretación derivada de o relacionada con la Promoción, los Participantes
                con el simple hecho de registrar su participación acuerdan someterse a las
                leyes vigentes en los Estados Unidos Mexicanos, así como a la jurisdicción de
                las autoridades y tribunales competentes la Ciudad de México, razón por la
                cual, por su participación, renuncian expresamente a cualquier otro fuero o
                jurisdicción que por razón de su domicilio presente o futuro, nacionalidad o
                por cualquier otra situación o derecho pudiere corresponderles. </span></p>

                <p class=MsoListParagraph><span lang=ES-TRAD style='font-size:11.0pt;
                font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                -18.0pt'><b><span lang=ES-TRAD style='font-family:"Arial",sans-serif'>5)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>AVISO
                PROFECO: PFC.C.A./000300-2022</span></p>

                <p class=MsoNormal><span lang=ES-TRAD>&nbsp;</span></p>

                </div>

           </template>
           <template v-else-if="footer == 2">
               
                <div class=WordSection1>

                <p class=MsoNormal><b style='mso-bidi-font-weight:normal'><span lang=ES-TRAD
                style='font-family:"Arial",sans-serif;mso-ansi-language:ES-TRAD'>AVISO DE
                PRIVACIDAD: <o:p></o:p></span></b></p>

                <p class=MsoNormal><b style='mso-bidi-font-weight:normal'><span lang=ES-TRAD
                style='font-family:"Arial",sans-serif;mso-ansi-language:ES-TRAD'><o:p>&nbsp;</o:p></span></b></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                font-family:"Arial Rounded MT Bold",sans-serif'>"CON EL DORADO BRILLAS" del </span><b
                style='mso-bidi-font-weight:normal'><span style='font-size:11.0pt;font-family:
                "Arial Rounded MT Bold",sans-serif;mso-bidi-font-family:Arial'>del 23 de marzo
                del 2022 al 07 de junio del 2022</span></b><span style='font-size:11.0pt;
                font-family:"Arial Rounded MT Bold",sans-serif'>. IMAQUINANDO S.A. DE C.V. con
                domicilio en Av. Amores 1153 2 Col del Valle Centro, alcaldía Benito Juárez,
                03100, en la Ciudad de México, es responsable de la confidencialidad, uso y
                protección de la información personal que nos llegare a proporcionar por los
                distintos medios que utilizamos para la difusión de bienes y servicios. Por lo
                anterior su información personal será utilizada para fines de identificación en
                cualquier tipo de relación jurídica o de negocios que realice con nosotros,
                incluyendo la venta y adquisición de los productos o servicios ofertados,
                hacerle llegar los productos adquiridos, enviarle nuestro catalogo de
                promociones, productos y servicios, invitarle a participar en eventos,
                concursos y sorteos y para fines mercadológicos y de prospección comercial.
                Para las finalidades anteriores, podríamos requerirle su nombre, dirección,
                teléfono, correo electrónico, RFC, fecha y lugar de nacimiento, sexo,
                nacionalidad, edad, información crediticia y patrimonial. Para el caso que se
                desee limitar el uso o divulgación de su información personal, ejercitar sus
                derechos de acceder, rectificar y cancelar sus datos personales, así como de
                oponerse al tratamiento de los mismos y revocar el consentimiento que para tal
                fin nos haya otorgado, lo podrá realizar a través de los siguientes canales de
                atención: Centro de atención Telefónica a los teléfonos 5556884444 y 5591972000
                o a través del correo electrónico </span><a href="mailto:hola@imakinando.mx"><span
                style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>hola@imakinando.mx</span></a><span
                style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>. En
                ambos casos se le informarán los procedimientos a seguir. Le informaremos que
                sus datos personales no serán transferidos a terceros para fines distintos a
                los necesarios para brindarle oportunamente los servicios y/o productos
                adquiridos, su participación en cualquier concurso o sorteo y entrega de sus
                premios, salvaguardando su protección y confidencialidad, sin que para ello sea
                necesario obtener su autorización en términos del artículo 37 de la Ley Federal
                de Protección de Datos Personales en Posesión de los Particulares. Para verificar
                los candados de seguridad SSL cookies y otras herramientas de rastreo a favor
                de visitar *seguridad de sitio*. Para verificar los cambios al presente aviso,
                lo podrá realizar en:<o:p></o:p></span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                font-family:"Arial Rounded MT Bold",sans-serif'><o:p>&nbsp;</o:p></span></p>

                <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                font-family:"Arial Rounded MT Bold",sans-serif'><o:p>&nbsp;</o:p></span></p>

                <p class=MsoNormal><a href="http://www.coneldoradovalesoro.com.mx"><b
                style='mso-bidi-font-weight:normal'><span lang=ES-TRAD style='font-size:11.0pt;
                font-family:"Arial Rounded MT Bold",sans-serif;mso-bidi-font-family:Arial;
                mso-ansi-language:ES-TRAD'>www.coneldoradovalesoro.com.mx</span></b></a><b
                style='mso-bidi-font-weight:normal'><span lang=ES-TRAD style='font-size:11.0pt;
                font-family:"Arial Rounded MT Bold",sans-serif;mso-bidi-font-family:Arial;
                mso-ansi-language:ES-TRAD'><o:p></o:p></span></b></p>

                <p class=MsoNormal><a href="http://www.imakinando.com.mx"><b style='mso-bidi-font-weight:
                normal'><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                mso-bidi-font-family:Arial;mso-ansi-language:ES-TRAD'>www.imakinando.com.mx</span></b></a><b
                style='mso-bidi-font-weight:normal'><span lang=ES-TRAD style='font-size:11.0pt;
                font-family:"Arial Rounded MT Bold",sans-serif;mso-bidi-font-family:Arial;
                mso-ansi-language:ES-TRAD'><o:p></o:p></span></b></p>

                <p class=MsoNormal><span lang=ES-TRAD style='font-family:"Arial",sans-serif;
                mso-ansi-language:ES-TRAD'><o:p>&nbsp;</o:p></span></p>

                <p class=MsoNormal><span lang=ES-TRAD style='mso-ansi-language:ES-TRAD'><o:p>&nbsp;</o:p></span></p>

                </div>

           </template>
           <template v-else>
                <div class="text-center mt-5" style="color:#013352;">
                    <h2><b>Contacto</b></h2>
                    <p>Déjanos tu mensaje y dinos cómo podemos ayudarte.</p>
                </div>
                <b-form @submit="store" autocomplete="off">
                  <b-row class="justify-content-center mt-5">
                      <b-col cols="12" md="6" >
                            <v-card elevation="4" shaped outlined>                       
                              <v-card-text>
                                <br><br>
                                  <b-row>
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Nombre:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                          <b-form-input v-model="nombre"  placeholder="Nombre completo" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'nombre' )">Campo requerido</small>
                                      </b-col>
                                      <!--  -->
                                      <b-col cols="3" style="margin-top:-1%;">
                                          <p>Email:</p>
                                      </b-col>
                                      <b-col cols="9" style="margin-top:-2%;">
                                          <b-form-input v-model="email"  placeholder="Email" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'email' )">Campo requerido</small>
                                      </b-col>
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Asunto:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                          <b-form-input v-model="asunto"  placeholder="Asunto" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'asunto' )">Campo requerido</small>
                                      </b-col>  
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Mensaje:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                              <b-form-textarea
                                                id="textarea"
                                                v-model="text"
                                                placeholder="Escribe aquí..."
                                                rows="3"
                                                max-rows="6"
                                              ></b-form-textarea>
                                          <small class="text-danger" v-if="errors.includes( 'text' )">Campo requerido</small>
                                      </b-col>  
                                  </b-row>   
                                  <br><br>
                              </v-card-text>
                            </v-card>
                            <center>
                              <br>
                              <v-btn class="mt-5"   dark color="#013352"  style="text-transform: none;" type="submit" >
                                  Enviar  <b-spinner v-if="loader" small></b-spinner>
                              </v-btn>
                            </center>
                      </b-col>
                  </b-row>
                </b-form>
           </template>
          </v-container>        
        </v-card>
      </v-dialog>
    </v-row>
    <v-card
      flat
      tile
      class=" lighten-1 white--text text-center"
      style="background:#ea4c20; width: 100%;"
    >
      <v-card-text class="white--text pt-0">
          <br>

         <div class="text-center" style="font-size: 20px;">
                <span style="cursor: pointer;"  @click="dialogOpen(1)">Términos y Condiciones</span> | <span style="cursor: pointer;" @click="dialogOpen(2)" >Aviso de Privacidad</span>
                <!-- |<span style="cursor: pointer;" @click="dialogOpen(3)">Contáctanos</span> -->
                <br><br>
                <span style="font-size: 12px;">2022© - Atún El Dorado<sup>®</sup> Todos los derechos reservados.</span>
                
         </div>


      </v-card-text>
      <br>
    </v-card>
  </v-footer>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios'
  export default {
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        footer:0,
        errors:[],
        nombre:"",
        email:"",
        asunto:"",
        text:"",
        loader:false,
        archivo:"",
        // 
        currentPage: 0,
        pageCount: 0,
        page:1,
      }
    },
    methods:{
      dialogOpen(id){
        this.footer = id;
        this.dialog = true ;
        this.currentPage = 0;
        this.pageCount = 0;
        this.page = 1;
      },
      store(e) {
            e.preventDefault();
            this.errors = [];
           
            let model = this.prepareModel()
           if(!this.nombre){
                this.errors.push('nombre');
            }else if(!this.email ){
                this.errors.push('email');
            }else if(!this.asunto){
                this.errors.push('asunto');
            }else if(!this.text){
                this.errors.push('text');
            }else{
                this.loader = true;
                axios.post("https://api.eligeganar.com/api/registro/contacto", model).then(
                    response => {
                        console.log(response)      
                        this.loader = false;  
                            this.clear();
                            Swal.fire(
                                '¡List@!',
                                'Tu correo se ha enviado exitosamente.',
                                'success'
                            )
                    },
                    error => {
                        this.loader = false;
                        console.log(error);
                    }
                )
            }
      },
      prepareModel() {
            let model = new FormData();
            model.append("nombre", this.nombre)
            model.append("email", this.email)
            model.append("asunto", this.asunto)
            model.append("mensaje", this.text)
            return model;
      },
      clear(){
        this.nombre = "";
        this.email = "";
        this.asunto = "";
        this.text = "";
        this.errors = [];
      },
    }
  }
</script>