<template>
  <div>
        <b-navbar type="dark" variant="faded" style="background:#ea4c20;" >
           <b-navbar-nav :style="!isMobile ? 'margin-left:69%;': 'margin-left:14%;'">
            <b-nav-item href="#" right @click="$router.push('/')"><span style="color:#fff;">Inicio</span></b-nav-item>
            <b-nav-item href="#" right @click="abrirNav(1)" class="ml-5"><span style="color:#fff;">Bases</span></b-nav-item>
            <b-nav-item href="#" right @click="abrirNav(2)" class="ml-5"><span style="color:#fff;">Contacto</span></b-nav-item>
           </b-navbar-nav>
        </b-navbar>

            <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        style="background:#ea4c20;"
      >
        
        <v-card>
          <v-toolbar
            dark
            color="#ea4c20"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="nave == 1">Bases del consurso “Con el Dorado Vales Oro</v-toolbar-title>
            <v-toolbar-title v-else-if="nave == 2">Contáctanos</v-toolbar-title>
          </v-toolbar>
          <v-container class="mt-5">
              <v-img src="https://dev.coneldoradovalesoro.com.mx/logo.png" max-width="150"></v-img>
              <br><br>
              <hr style=" border-top: 3px solid #979797 !important;">
            <template v-if="nave == 1">     
                    
                    <div class=WordSection1 style="margin-top:-10%;">

                        <p class=MsoNormal><b><span style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal><b><span style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal><b><span style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal align=center style='text-align:center'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal align=center style='text-align:center'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>BASES
                        DEL CONCURSO "CON EL DORADO VALES ORO"</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>CONCURSO BASADO EN
                        CONOCIMIENTOS.</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>1.
                        Nombre y domicilio del proveedor que realiza la promoción:</span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>
                        </span><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>IMAQUINANDO
                        S.A. de C.V. Av. Amores 1153-2, col. Del Valle Centro, Alcaldía Benito Juárez,
                        03100, en la Ciudad de México. Tels. 5591972000 / 5556884444 </span><a
                        href="mailto:hola@imakinando.mx"><span style='font-family:"Arial Rounded MT Bold",sans-serif'>contacto@imakinando.mx</span></a><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>
                        </span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoListParagraph style='margin-left:0cm;text-align:justify'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>2.
                        VIGENCIA DE LA PROMOCIÓN:</span></b><span style='font-size:11.0pt;font-family:
                        "Arial Rounded MT Bold",sans-serif'> </span></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>Las compras que participan en
                        el concurso serán exclusivamente las que se realicen <b>del 23 de marzo del
                        2022 al 07 de junio del 2022.</b></span></p>

                        <p class=MsoListParagraphCxSpFirst style='margin-left:7.1pt;text-align:justify'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'></span></b></p>

                        <p class=MsoListParagraphCxSpMiddle style='margin-left:0cm;text-align:justify'><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Periodo
                        permitido para participar y registro de tickets de compra será <b>del 23 de
                        marzo del 2022 al 07 de junio del 2022..</b></span></p>

                        <p class=MsoListParagraphCxSpMiddle style='margin-left:0cm;text-align:justify'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoListParagraphCxSpLast style='margin-left:0cm;text-align:justify'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>3. MECÁNICA:</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>Por cada compra de $150.00
                        (ciento cincuenta pesos) el participante enviará el ticket de compra de ATÚN EL
                        DORADO en el número de WhatsApp 5540055066 y podrá participar en el concurso "CON
                        EL DORADO VALES ORO", para que, con base en sus conocimientos, gane uno de los
                        15 premos disponibles. Al primer registro de ticket y respuesta correcta, el
                        participante genera un PERFIL y las posteriores participaciones contestando
                        correctamente le darán PUNTOS. Al registrar, el sistema automáticamente le
                        enviará una pregunta al participante, la cual deberá ser contestada en un
                        tiempo límite de 30 segundos. Los 15 participantes que registren más tickets
                        con sus respuestas correctas, ganarán uno de los 15 premios. Habrá 10 preguntas
                        diferentes relacionadas a ATÚN EL DORADO. Al final de la promoción, los 15 participantes
                        que más tickets hayan registrado, contestado correctamente las respectivas
                        respuestas y que se establezcan en los 15 primeros lugares, serán los
                        ganadores. </span></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>Al registrar cada compra, el
                        participante deberá incluir:</span></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(i)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Nombre</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(ii)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Teléfono</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(iii)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Dirección
                        de correo electrónico</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(iv)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>No.
                        Tícket de compra</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(v)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Monto
                        de compra</span></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoListParagraphCxSpFirst style='margin-left:0cm;text-align:justify'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoListParagraphCxSpLast style='margin-left:0cm;text-align:justify'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>4. CONDICIÓN
                        DE PARTICIPACIÓN:</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>Por cada ticket de compra de
                        $150.00 (ciento cincuenta pesos) en la compra de ATÚN EL DORADO en la cadena
                        mayorista EL ZORRO </span><span lang=ES-TRAD style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>y sus tiendas participantes</span><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>
                        </span><span style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>a
                        nivel nacional</span><span lang=ES-TRAD style='font-size:11.0pt;font-family:
                        "Arial Rounded MT Bold",sans-serif'>, en lo sucesivo el "Producto",
                        comercializados por PINSA COMERCIAL </span><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>dentro del periodo de vigencia
                        de la dinámica. </span></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNoSpacing style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>5. NÚMERO DE PREMIOS.</span></b></p>

                        <p class=MsoNoSpacing style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>La
                        Promoción se limita a los premios indicados. </span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>TOTAL
                        DE PREMIOS: 15 (quince).</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=692
                        style='width:519.0pt;border-collapse:collapse'>
                        <tr style='height:31.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:31.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>PREMIO</span></p>
                        </td>
                        <td width=263 nowrap style='width:197.0pt;border:solid windowtext 1.0pt;
                        border-left:none;padding:0cm 3.5pt 0cm 3.5pt;height:31.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>DESCRIPCIÓN</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border:solid windowtext 1.0pt;
                        border-left:none;padding:0cm 3.5pt 0cm 3.5pt;height:31.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>CANTIDAD</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border:solid windowtext 1.0pt;
                        border-left:none;padding:0cm 3.5pt 0cm 3.5pt;height:31.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>PRECIO UNITARIO</span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>PRIMER
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>AMAZON
                        DOT</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>SEGUNDO
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>AMAZON
                        DOT</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>TERCER
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>AMAZON
                        DOT</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>CUARTO
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>TELÉFONO
                        CELULAR ANDROID</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>QUINTO
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>TELÉFONO
                        CELULAR ANDROID</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>SEXTO
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>TELÉFONO
                        CELULAR ANDROID</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>SÉPTIMO
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>BICICLETA
                        DE MONTAÑA</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>OCTAVO
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>BICICLETA
                        DE MONTAÑA</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>NOVENO
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>BICICLETA
                        DE MONTAÑA</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>DÉCIMO
                        LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>AIR
                        FRYER</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>DÉCIMO
                        PRIMER LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>AIR
                        FRYER</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>DÉCIMO
                        SEGUNDO LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>AIR
                        FRYER</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>DÉCIMO
                        TERCER LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>JUEGO
                        DE SARTENES</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>DÉCIMO
                        CUARTO LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>JUEGO
                        DE SARTENES</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap style='width:139.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>DÉCIMO
                        QUINTO LUGAR</span></p>
                        </td>
                        <td width=263 nowrap valign=bottom style='width:197.0pt;border-top:none;
                        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal><span style='font-family:"Calibri",sans-serif;color:black'>JUEGO
                        DE SARTENES</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>1</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$2,500.00
                        </span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap valign=bottom style='width:139.0pt;padding:0cm 3.5pt 0cm 3.5pt;
                        height:36.0pt'></td>
                        <td width=263 nowrap style='width:197.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=right style='text-align:right'><span
                        style='font-family:"Calibri",sans-serif;color:black'>CANTIDAD TOTAL DE
                        PREMIOS</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>15</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>&nbsp;</span></p>
                        </td>
                        </tr>
                        <tr style='height:36.0pt'>
                        <td width=185 nowrap valign=bottom style='width:139.0pt;padding:0cm 3.5pt 0cm 3.5pt;
                        height:36.0pt'></td>
                        <td width=263 nowrap style='width:197.0pt;border:solid windowtext 1.0pt;
                        border-top:none;padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=right style='text-align:right'><span
                        style='font-family:"Calibri",sans-serif;color:black'>TOTAL EN PREMIOS</span></p>
                        </td>
                        <td width=109 nowrap style='width:82.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>&nbsp;</span></p>
                        </td>
                        <td width=135 nowrap style='width:101.0pt;border-top:none;border-left:none;
                        border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                        padding:0cm 3.5pt 0cm 3.5pt;height:36.0pt'>
                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-family:"Calibri",sans-serif;color:black'>$37,500.00 </span></p>
                        </td>
                        </tr>
                        </table>

                        <p class=MsoNormal style='text-align:justify;text-autospace:none'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify;text-autospace:none'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify;text-autospace:none'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify;text-autospace:none'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'>MONTO TOTAL DE LA PIRÁMIDE: $37,500.00 (treinta y siete mil
                        quinientos pesos 00/100 m.m.) IVA INCLUIDO</span></b></p>

                        <p class=MsoNormal style='text-align:justify;text-autospace:none'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify;text-autospace:none'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'>6. Cobertura Geográfica y Establecimientos donde se realizará la
                        Promoción</span></b></p>

                        <p class=MsoNormal style='text-align:justify;text-autospace:none'><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify;text-autospace:none'><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif;
                        color:black'>La Promoción será de carácter nacional</span><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'> y será
                        efectiva <b><u>para compras generadas exclusivamente en las tiendas mayoristas de
                        Grupo Zorro. </u></b></span></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>7.Productos
                        participantes en la Promoción. </span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>El
                        producto cuyas compras serán participantes en la Promoción son: </span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>a). <b><u>TODOS
                        LOS PRODUCTOS ATÚN EL DORADO</u></b></span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>8. PARTICIPANTES</span></b><span
                        lang=ES-TRAD style='font-family:"Arial Rounded MT Bold",sans-serif'>.</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Podrán
                        participar todas aquellas personas mayores de edad que residan en territorio
                        nacional (en lo sucesivo e indistintamente, de manera conjunta los
                        "Participantes" o individualmente el "Participante").</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>En caso
                        de resultar ganador, el Participante deberá de presentar original y copia de
                        una identificación oficial vigente con su fotografía y firma, tales como </span><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>credencial
                        para votar, cédula profesional, licencia para conducir, pasaporte, credencial
                        del Instituto Nacional de las Personas Mayores vigente</span><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>
                        o, en caso de ser extranjero, el pasaporte expedido por su país de origen y el
                        documento que acredite su legal estancia en territorio nacional (todas las
                        anteriores, indistintamente referidas como la "Identificación Oficial").</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>En caso
                        de que un Participante no resida en la República Mexicana y figure como
                        candidato al Premio (según dicho término es definido adelante), su
                        participación será anulada inmediatamente y el Participante inmediato siguiente
                        més próximo a satisfacer los requisitos para la obtención del Premio será
                        acreedor a dicho Premio en sustitución, siempre y cuando este último cumpla con
                        todos los requisitos que se mencionan en el presente documento. Esto aplicará
                        sucesivamente, hasta que un Participante candidato al Premio cumpla con los
                        requisitos establecidos en estas Bases para su obtención. </span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>9. PROCESO DE INSCRIPCIÓN Y
                        MECÁNICA</span></b></p>

                        <p class=MsoNormal style='margin-left:7.1pt;text-align:justify'><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Una vez
                        que el cliente cumpla con la condición de participación señalada, para llevar a
                        cabo la mecánica, será necesario lo siguiente:</span></p>

                        <p class=MsoNormal style='margin-left:7.1pt;text-align:justify'><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>a)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Registro
                        de Tickets:</span></b><span style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>
                        El cliente deberá registrar su ticket de compra dentro de la vigencia de la
                        promoción, es decir, Del <b>23 de marzo del 2022 al 07 de junio del 2022.</b>
                        en el Whatsapp 5540055066 así como los datos solicitados.</span></p>

                        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>b)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'></span><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Para
                        ser un Participante el cliente deberá comprar un mínimo de: $150.00 (ciento
                        cincuenta pesos) que equivale a UNA (1) participación. <b><u>Las compras de ATÚN
                        EL DORADO son ilimitadas.</u></b></span></p>

                        <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>c)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>El
                        Participante tendrá acceso al concurso, y deberá ingresar en el número WhatsApp
                        5540055066 su (s) números de ticket (es) de compra para registrarlos y sus
                        datos solicitados, para que con base en sus conocimientos en el producto ATÚN
                        EL DORADO, participe para ser uno de los 15 ganadores y obtenga su premio
                        indicado en el cuadro anterior según el puntaje logrado.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>d)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>El
                        site </span><a href="http://www.coneldoradovalesoro.com.mx"><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>www.coneldoradovalesoro.com.mx</span></a><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>
                        </span><span lang=ES-TRAD></span><span style='font-size:11.0pt;font-family:
                        "Arial Rounded MT Bold",sans-serif'>estará disponible para que el cliente
                        conozca su posición en el ranking e i</span><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Incluir
                        la siguiente información previamente ingresada por cada participante:</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(vi)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Nombre</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(vii)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Teléfono</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(viii)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Dirección
                        de correo electrónico</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(ix)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>No.
                        Tícket de compra</span></p>

                        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
                        -36.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>(x)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Monto
                        de compra</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>10. RESTRICCIONES.</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>a)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>No
                        podrán participar en este programa los empleados, o los familiares de los
                        empleados, de las empresas El Zorro, </span></b><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>PINSA
                        Comercial, </span></b><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:
                        "Arial Rounded MT Bold",sans-serif'>Imaquinando</span></b><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>,
                        ni</span></b><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>
                        cualquier persona que tenga relación directa e indirecta con la Promoción</span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>.
                        </span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>b)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>No
                        podrán participar los tickets de compra que hayan sido objeto de devoluciones
                        del Producto.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>c)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>No
                        se permite la participación con el mismo ticket.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>d)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Los
                        Premios no son reembolsables en dinero ni en especie.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:35.0pt;text-align:justify;text-indent:
                        -35.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>e)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Solo
                        existirá 1 (un) Premio para cada uno de los 15 (quince) ganadores </span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>f)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>El
                        Premio no podrá ser transferible. </span></p>

                        <p class=MsoNormal style='margin-left:35.0pt;text-align:justify;text-indent:
                        -35.0pt'><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>g)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Para
                        reclamar el Premio el Participante ganador deberá presentar obligatoriamente: <b>(i)
                        </b>original de el o los<b> </b>ticket(s) de compra, mismo(s) que deberán ser
                        anteriores a la fecha de participación, o dentro de las fechas y horarios a los
                        días de participación, <b>(ii) </b>original y copia de su Identificación
                        Oficial.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>En
                        caso de no satisfacer los requisitos anteriores, la persona o Participante no
                        tendrá derecho a reclamar el Premio.</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>11.
                        CRITERIOS DE DESEMPATE:</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>a).-
                        Primer criterio de desempate. - </span></b><span lang=ES-TRAD style='font-size:
                        11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Sera ganadores los
                        primeros 15 lugares, siendo aquellos Participantes que tengan el mayor registro
                        de número de PUNTOS logrados por respuestas correctas en cada participación y
                        número de tickets acumulados por compra de producto Atún El Dorado en las
                        tiendas mayoristas El Zorro </span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>b).-
                        Segundo criterio de desempate.- </span></b><span lang=ES-TRAD style='font-size:
                        11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>En caso de empate en el
                        número de compras y monto acumulado de producto, se considerará como Segundo criterio
                        de desempate, las fecha y horarios que conste en el ticket y en el registro de
                        participación dentro del sitio web. Los quince Participantes que además de
                        satisfacer el Primer Criterio se hubieran registrado con <b>la fecha y hora más
                        cercanos a la fecha de inicio de la Promoción</b> serán ganadores.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Los
                        criterios de desempate antes descritos se aplicarán en el orden expuesto,
                        conforme han sido listados, de manera sucesiva.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>12. MECÁNICA PARA LA
                        ENTREGA DE LOS PREMIOS </span></b></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Los
                        Ganadores serán contactados y sus Premios podrán ser entregados del <b>08 de
                        junio al 27 de julio de 2022</b>, en un horario de oficina de 9:30 (nueve
                        treinta) hrs. a las 18:00 (dieciocho) hrs., con previa cita en las oficinas de IMAKINANDO,
                        ubicadas en: </span><span lang=ES-TRAD style='font-size:11.0pt;font-family:
                        "Arial Rounded MT Bold",sans-serif;color:black'>Av. Amores 1153-2 col. Del
                        Valle Centro</span><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>,
                        C.P. 03100 Alcaldía Benito Juárez 03100 Ciudad de México. En caso de que alguno
                        de los premios resulten en el interior de la república, el premio se enviará al
                        domicilio de cada ganador. </span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>En caso
                        de que el Participante ganador no se presente en las fechas y horarios indicados
                        para reclamar su Premio, el Proveedor no está obligado a entregar el Premio a
                        otro Participante.</span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>El
                        Participante deberá de presentar forzosamente su(s) ticket(es) de compra, así
                        como su Identificación Oficial para efecto de reclamo del Premio. éstos deberán
                        ser proporcionados en original y copia y estar en buen estado, de modo que sean
                        perfectamente legibles. El participante deberá firmar obligatoriamente un acuse
                        de recibido al momento de recibir su premio.</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify;vertical-align:baseline'><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>13.
                        EL PROVEEDOR PODRÁ:</span></b></p>

                        <p class=MsoListParagraphCxSpFirst style='margin-left:0cm;text-align:justify;
                        vertical-align:baseline'><b><span lang=ES-TRAD style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>(i)</span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>
                        Modificar la entrega del Premio y publicación de los mismos; <b>(ii)</b>
                        modificar estas bases; y <b>(iii)</b> cancelar, suspender o modificar la
                        Promoción por circunstancias no imputables al Proveedor y no previstas en estas
                        Bases, o que constituyan hechos de caso fortuito o fuerza mayor. (iv) Retener
                        el premio en caso de que el ganador no presente el ticket registrado. </span></p>

                        <p class=MsoListParagraphCxSpMiddle style='margin-left:0cm;text-align:justify;
                        vertical-align:baseline'><span lang=ES-TRAD style='font-size:11.0pt;font-family:
                        "Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoListParagraphCxSpLast style='margin-left:0cm;text-align:justify;
                        vertical-align:baseline'><span lang=ES-TRAD style='font-size:11.0pt;font-family:
                        "Arial Rounded MT Bold",sans-serif'>Toda modificación a los términos y
                        condiciones establecidos en estas Bases serán comunicadas al público en general
                        con al menos 1 (un) día hábil de anticipación a su entrada en vigor, efectuando
                        las publicaciones correspondientes según lo establecido en la <b>NOM 028 SCFI
                        2007</b> sobre prácticas comerciales, elementos de información en las
                        promociones coleccionables y/o promociones por medio de sorteos y concursos, y
                        demós legislación aplicable. </span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>14. EXCLUYENTES DE
                        RESPONSABILIDAD:</span></b></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>El Proveedor<i> </i></span><span
                        lang=ES-TRAD style='font-family:"Arial Rounded MT Bold",sans-serif'>no se hace responsables
                        por fallas o errores que se presenten en cualquier etapa del concurso
                        ocasionadas por caso fortuito o fuerza mayor, incluyendo cualquier falla en el
                        registro, conexión a Internet, así como fallas en el sistema del sitio web, o
                        de la App que no le sean imputables ni previsibles.</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>15. CONTACTO:</span></b><span
                        lang=ES-TRAD style='font-family:"Arial Rounded MT Bold",sans-serif'> </span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>Si tienes dudas o
                        comentarios respecto de este concurso, por favor envía un correo a la siguiente
                        dirección: </span><a href="mailto:contacto@imakinando.mx"><span
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>contacto@imakinando.mx</span></a><span
                        style='font-family:"Arial Rounded MT Bold",sans-serif'> </span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>La participación en este
                        concurso implica la aceptación incondicional de los siguientes Términos y
                        Condiciones, así como del Aviso de Privacidad de la Promoción. </span></p>

                        <h1 style='text-align:justify'><span lang=ES-TRAD style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif;color:black'>Términos y
                        Condiciones de la Promoción </span></h1>

                        <p class=MsoNoSpacing style='text-align:justify'><span lang=ES-TRAD
                        style='font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>16. CONDICIONES
                        GENERALES DE PARTICIPACIÓN </span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>El Premio
                        de la Promoción es intransferible, no reembolsable y tampoco es canjeable por
                        dinero en efectivo ni puede cederse para el pago de deudas de ningún tipo.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><b><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>17. TÉRMINOS
                        LEGALES DE LA PROMOCIÓN.</span></b></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>1)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Al
                        momento de la aceptación del Premio, el Participante se obliga a sacar en paz y
                        a salvo al Proveedor y organizador, así como sus accionistas, directores,
                        empleados, miembros del consejo de administración, dependientes, entre otros, de
                        cualquier queja, reclamación, denuncia o cualquier otro equivalente,
                        relacionada con cualquier daño, pérdida, gasto, erogación o contingencia,
                        originada con motivo de la aceptación, utilización o aprovechamiento del Premio
                        por el Participante. Los participantes aceptan la responsabilidad derivada del
                        uso y aprovechamiento del Premio o de su participación dentro de la Promoción,
                        por cualquier daño, pérdida o contingencia que pudiere ocasionarse o ser
                        reclamada por cualquier tercero.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>2)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>La
                        información de los Participantes, proporcionada con motivo del registro de su
                        participación, será considerada como confidencial y únicamente será utilizada para
                        las finalidades y conforme a los términos previstas en el respectivo Aviso de
                        Privacidad, el cual forma parte integrante de las presentes Bases de la
                        Promoción y cuya aceptación es requisito indispensable para ser Participante en
                        la Promoción.</span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:35.4pt;text-align:justify'><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Los
                        Participantes se obligan a proporcionar toda la información y/o documentación requerida
                        conforme a las Bases de forma veraz, en el momento y tiempo que el Proveedor se
                        los solicite a efecto de verificar y corroborar los términos de su participación.
                        </span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>3)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>El
                        Proveedor no será responsable por la falsedad de los datos proporcionados, con
                        motivo del registro del Participante en la Promoción; éste último será el único
                        y exclusivo responsable de la información otorgada. </span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>4)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>Para
                        efectos de cualquier contingencia, queja, denuncia, reclamación o conflicto o
                        interpretación derivada de o relacionada con la Promoción, los Participantes con
                        el simple hecho de registrar su participación acuerdan someterse a las leyes
                        vigentes en los Estados Unidos Mexicanos, así como a la jurisdicción de las
                        autoridades y tribunales competentes la Ciudad de México, razón por la cual,
                        por su participación, renuncian expresamente a cualquier otro fuero o
                        jurisdicción que por razón de su domicilio presente o futuro, nacionalidad o
                        por cualquier otra situación o derecho pudiere corresponderles. </span></p>

                        <p class=MsoListParagraph><span lang=ES-TRAD style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
                        -18.0pt'><b><span lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>5)<span
                        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>AVISO
                        PROFECO NUMERO: <b>PFC.C.A./000300-2022</b></span></p>

                        <p class=MsoNormal style='text-align:justify'><span lang=ES-TRAD
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal><span lang=ES-TRAD style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal><b><span lang=ES-TRAD style='font-family:"Arial",sans-serif'>18.
                         AVISO DE PRIVACIDAD: </span></b></p>

                        <p class=MsoNormal><b><span lang=ES-TRAD style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>AVISO
                        DE PRIVACIDAD.</span></p>

                        <p class=MsoNormal align=center style='text-align:center'><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>&nbsp;</span></p>

                        <p class=MsoNormal style='text-align:justify'><span style='font-size:11.0pt;
                        font-family:"Arial Rounded MT Bold",sans-serif'>"CON EL DORADO BRILLAS" del </span><b><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>del 23
                        de marzo del 2022 al 07 de junio del 2022</span></b><span style='font-size:
                        11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>. IMAQUINANDO S.A. DE
                        C.V. con domicilio en Av. Amores 1153  2 Col del Valle Centro, alcaldía Benito
                        Juárez, 03100, en la Ciudad de México, es responsable de la confidencialidad,
                        uso y protección de la información personal que nos llegare a proporcionar por
                        los distintos medios que utilizamos para la difusión de bienes y servicios. Por
                        lo anterior su información personal será utilizada para fines de identificación
                        en cualquier tipo de relación jurídica o de negocios que realice con nosotros,
                        incluyendo la venta y adquisición de los productos o servicios ofertados,
                        hacerle llegar los productos adquiridos, enviarle nuestro catalogo de
                        promociones, productos y servicios, invitarle a participar en eventos,
                        concursos y sorteos y para fines mercadológicos y de prospección comercial.
                        Para las finalidades anteriores, podráamos requerirle su nombre, dirección,
                        teléfono, correo electrónico, RFC, fecha y lugar de nacimiento, sexo,
                        nacionalidad, edad, información crediticia y patrimonial. Para el caso que se
                        desee limitar el uso o divulgación de su información personal, ejercitar sus
                        derechos de acceder, rectificar y cancelar sus datos personales, así como de
                        oponerse al tratamiento de los mismos y revocar el consentimiento que para tal
                        fin nos haya otorgado, lo podrá realizar a través de los siguientes canales de
                        atención: Centro de atención Telefónica a los teléfonos 5556884444 y 5591972000
                        o a través del correo electrónico </span><a href="mailto:contacto@imakinando.mx"><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>contacto@imakinando.mx</span></a><span
                        style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'> . En
                        ambos casos se le informarán los procedimientos a seguir. Le informaremos que
                        sus datos personales no serán transferidos a terceros para fines distintos a
                        los necesarios para brindarle oportunamente los servicios y/o productos adquiridos,
                        su participación en cualquier concurso o sorteo y entrega de sus premios,
                        salvaguardando su protección y confidencialidad, sin que para ello sea
                        necesario obtener su autorización en términos del artículo 37 de la Ley Federal
                        de Protección de Datos Personales en Posesión de los Particulares. Para
                        verificar los candados de seguridad SSL cookies y otras herramientas de rastreo
                        a favor de visitar *seguridad de sitio*. Para verificar los cambios al presente
                        aviso, lo podrá realizar en:</span></p>

                        <p class=MsoNormal><a href="http://www.coneldoradovalesoro.com.mx"><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>www.coneldoradovalesoro.com.mx</span></b></a></p>

                        <p class=MsoNormal><a href="http://www.imakinando.com.mx"><b><span
                        lang=ES-TRAD style='font-size:11.0pt;font-family:"Arial Rounded MT Bold",sans-serif'>www.imakinando.com.mx</span></b></a></p>

                        <p class=MsoNormal><span lang=ES-TRAD style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

                    </div>
         
           </template>
           <template v-else>
                <div class="text-center mt-5" style="color:#000;">
                    <h2><b>Contacto</b></h2>
                    <p  style="color:rgb(248 172 0);">Déjanos tu mensaje y dinos cómo podemos ayudarte.</p>
                </div>
                <b-form @submit="store" autocomplete="off">
                  <b-row class="justify-content-center mt-5">
                      <b-col cols="12" md="6" >
                            <v-card elevation="4" shaped outlined>                       
                              <v-card-text>
                                <br><br>
                                  <b-row>
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Nombre:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                          <b-form-input v-model="nombre"  placeholder="Nombre completo" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'nombre' )">Campo requerido</small>
                                      </b-col>
                                      <!--  -->
                                      <b-col cols="3" style="margin-top:-1%;">
                                          <p>Email:</p>
                                      </b-col>
                                      <b-col cols="9" style="margin-top:-2%;">
                                          <b-form-input v-model="email"  placeholder="Email" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'email' )">Campo requerido</small>
                                      </b-col>
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Asunto:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                          <b-form-input v-model="asunto"  placeholder="Asunto" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'asunto' )">Campo requerido</small>
                                      </b-col>  
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Mensaje:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                              <b-form-textarea
                                                id="textarea"
                                                v-model="text"
                                                placeholder="Escribe aquí..."
                                                rows="3"
                                                max-rows="6"
                                              ></b-form-textarea>
                                          <small class="text-danger" v-if="errors.includes( 'text' )">Campo requerido</small>
                                      </b-col>  
                                  </b-row>   
                                  <br><br>
                              </v-card-text>
                            </v-card>
                            <center>
                              <br>
                              <v-btn class="mt-5"   dark color="#ea4c20"  style="text-transform: none;" type="submit" >
                                  Enviar  <b-spinner v-if="loader" small></b-spinner>
                              </v-btn>
                            </center>
                      </b-col>
                  </b-row>
                </b-form>
           </template>
          </v-container>        
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data () {
      return {
        loader:false,
        isMobile: this.$mobile(),
        nave:0,
        errors:[],
        nombre:"",
        email:"",
        asunto:"",
        text:"",
         dialog: false,
      }
    },
    methods:{
     store(e) {
            e.preventDefault();
            this.errors = [];
           
            let model = this.prepareModel()
           if(!this.nombre){
                this.errors.push('nombre');
            }else if(!this.email ){
                this.errors.push('email');
            }else if(!this.asunto){
                this.errors.push('asunto');
            }else if(!this.text){
                this.errors.push('text');
            }else{
                this.loader = true;
                this.$api.post("rankin/correo", model).then(
                    response => {
                        console.log(response)      
                        this.loader = false;  
                            this.clear();
                            Swal.fire(
                                '¡List@!',
                                'Tu correo se ha enviado exitosamente.',
                                'success'
                            )
                    },
                    error => {
                        this.loader = false;
                        console.log(error);
                    }
                )
            }
      },
      prepareModel() {
            let model = new FormData();
            model.append("nombre", this.nombre)
            model.append("email", this.email)
            model.append("asunto", this.asunto)
            model.append("mensaje", this.text)
            return model;
      },
      clear(){
        this.nombre = "";
        this.email = "";
        this.asunto = "";
        this.text = "";
        this.errors = [];
      },
      abrirNav(id){
          this.nave = id;
          this.dialog = true;
      }
    }
}
</script>

<style>

</style>