<template>
  <div>
    <v-container class="mt-5">
        <div class="text-center mt-5" style="color:#000;">
            <h1>Bienvenido</h1>
            <h5 style="color:rgb(248 172 0);">CON EL DORADO VALES ORO</h5>
        </div>
             <b-form @submit="store" autocomplete="off">
                    <b-row class="justify-content-center mt-5">
                        <b-col cols="12" md="6" >
                            <v-card elevation="4" shaped outlined>                       
                                <v-card-text>
                                    <p class="text-center" > Registra tu ticket y participa.</p>
                                    <b-row>
                                        <b-col cols="3">
                                            <p>Nombre Completo:</p>
                                        </b-col>
                                        <b-col cols="9">
                                            <b-form-input v-model="nombre" placeholder="Nombre Completo"></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'nombre' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3"  style="margin-top:-1%;" >
                                            <p>Email:</p>
                                        </b-col>
                                        <b-col cols="9"  style="margin-top:-2%;">
                                            <b-form-input v-model="email"  placeholder="Email" ></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'email' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3"  style="margin-top:-1%;">
                                            <p>Teléfono:</p>
                                        </b-col>
                                        <b-col cols="9"  style="margin-top:-2%;">
                                            <b-form-input v-model="telefono" placeholder="Teléfono"></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'telefono' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3"  style="margin-top:-1%;" >
                                            <p>Folio Ticket:</p>
                                        </b-col>
                                        <b-col cols="9"  style="margin-top:-2%;">
                                            <b-form-input v-model="ticket"  placeholder="Folio Ticket" ></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'ticket' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;">
                                            <p>Monto:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;">
                                            <b-form-input v-model="monto"  placeholder="Monto" ></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'Monto' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;">
                                            <p>Pregunta:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;">
                                            <!-- <b-form-input v-model="ciudad"  placeholder="Ciudad"></b-form-input> -->
                                            <select  class="form-control" v-model="preguntas">
                                                <option value="" selected disabled>Elige una opción</option>
                                                <option value="¿Qué presentación maneja la marca El Dorado?">¿Qué presentación maneja la marca El Dorado?</option>
                                                <option value="De qué color es el logo de El Dorado">De qué color es el logo de El Dorado</option>
                                                <option value="Termina la frase con El Dorado……">Termina la frase con El Dorado……</option>
                                                <option value="¿Cuál es el gramaje “regular” de El Dorado?">¿Cuál es el gramaje “regular” de El Dorado?</option>
                                                <option value="¿De qué color es la etiqueta de El Dorado aceite?">¿De qué color es la etiqueta de El Dorado aceite?</option>
                                            </select>
                                            <small class="text-danger" v-if="errors.includes( 'pregunta' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;">
                                            <p>Respuesta:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-1%;">
                                            <b style="color:rgb(248 172 0);">
                                                <template  v-if="preguntas == '¿Qué presentación maneja la marca El Dorado?'" >
                                                    Agua y aceite
                                                </template>
                                                <template v-else-if="preguntas == 'De qué color es el logo de El Dorado'">
                                                    Rojo
                                                </template>
                                                <template v-else-if="preguntas == 'Termina la frase con El Dorado……'">
                                                    Vales oro
                                                </template>
                                                <template v-else-if="preguntas == '¿Cuál es el gramaje “regular” de El Dorado?'">
                                                    130 gr
                                                </template>
                                                <template v-else-if="preguntas == '¿De qué color es la etiqueta de El Dorado aceite?'">
                                                    Anaranjado
                                                </template>
                                            </b>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;">
                                            <p>Correcta:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;">
                                            <select  class="form-control" v-model="correcta">
                                                <option value="" selected disabled>Elige una opción</option>
                                                <option value="Si">Si</option>
                                                <option value="No">No</option>
                                            </select>
                                            <small class="text-danger" v-if="errors.includes( 'correcta' )">Campo requerido</small>
                                            <hr v-if="correcta == 'No'">
                                            <b-form-input v-if="correcta == 'No'" v-model="ejemplo"  placeholder="Respuesta" ></b-form-input>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;">
                                            <p>Foto Ticket</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;">
                                            <v-file-input 
                                                v-model="imagen"
                                                label="Selecciona un archivo"
                                                
                                            ></v-file-input>
                                            <small class="text-danger" v-if="errors.includes( 'imagen' )">Campo requerido</small>
                                        </b-col>
                                    </b-row>   
                                    <br>                     
                                </v-card-text>                          
                            </v-card>
                            <div class="text-center">
                                <b-row class="justify-content-center">
                                    <b-col cols="4">
                                        <v-btn class="mt-5" block  dark color="#ea4c20"  style="text-transform: none;" type="submit" >
                                            Enviar  <b-spinner v-if="loader" small></b-spinner>
                                        </v-btn>
                                    </b-col>
                                </b-row>    
                            </div>
                            <br>
                            <hr>
                            <br>
                            <br>
                        </b-col>
                    </b-row>
                </b-form>
    </v-container> 
  </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data () {
      return {
          loader:false,
          nombre:"",
          email:"",
          telefono:"",
          ticket:"",
          monto:"",
          preguntas:"",
          respuesta:"",
          correcta:"",
          ejemplo:"",
          imagen:null,
          errors:[]
      }
    },
    methods:{
        store(e) {
            e.preventDefault();
            this.errors = [];
           
            let model = this.prepareModel()
            if(!this.nombre)
            {
                this.errors.push('nombre');
            }else if(!this.email){
                this.errors.push('email');
            }else if(!this.telefono){
                this.errors.push('telefono');
            }else if(!this.ticket){
                this.errors.push('ticket');
            }else if(!this.monto){
                this.errors.push('monto');
            }else if(!this.preguntas){
                this.errors.push('pregunta');
            }else if(!this.correcta){
                this.errors.push('correcta');
            }else if(!this.imagen){
                this.errors.push('imagen');
            }else{
                this.loader = true;
                let request = this.$api.post("registro/store", model);
                request.then(
                    response => {
                        console.log(response)      
                        this.loader = false;  
                        this.clear();
                        Swal.fire(
                            '¡List@!',
                            'Registro exitoso, sigue participando.',
                            'success'
                        )
                    },
                    error => {
                        this.loader = false;
                        console.log(error);
                    }
                )
            }
        },
        prepareModel() {
            let model = new FormData();
            model.append("nombre", this.nombre)
            model.append("email", this.email)
            model.append("telefono", this.telefono)
            model.append("ticket", this.ticket)
            model.append("monto", this.monto)
            model.append("preguntas", this.preguntas)
            model.append("correcta", this.correcta)
            model.append("imagen", this.imagen)
            model.append("ejemplo", this.ejemplo)
            return model;
        },
        clear(){
            this.nombre = "";
            this.email = "";
            this.telefono = "";
            this.ticket = "";
            this.monto = "";
            this.preguntas = "";
            this.correcta = "";
            this.ejemplo = "";
            this.imagen = null;
        }
    }
}
</script>

<style>

</style>