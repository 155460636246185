import Vue from 'vue'
import App from './App.vue'
import detectMobile from "./DetectecMobile"
import vuetify from './plugins/vuetify'
import helloWord from './components/HelloWorld.vue'
import registro from './components/Registro.vue'
import admin from './components/Admin.vue'
import api from "./api";
import VueRouter from 'vue-router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.prototype.$api = api;
Vue.config.productionTip = false
Vue.prototype.$mobile = detectMobile;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);


var router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: helloWord
    },
    {
      path: '/justcreative',
      component: registro
    },
    {
      path: '/admin-reporte',
      component: admin
    }
  ]
});

const app =  new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

export default app;