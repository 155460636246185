<template>
  <v-app>
      <navs></navs>
      <router-view ></router-view>
      <footers class="mt-5"></footers>
  </v-app>
</template>

<script>
import footers from './components/Footer';
import navs from './components/Nav';
export default {
  name: 'App',
  components: {
    footers,
    navs
  },
}
</script>
